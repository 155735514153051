<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Citric acid
        <chemical-latex content="(C6H8O7)" />
        , named for its natural occurrence in citrus fruits, is produced in large quantities
        industrially. This is often achieved through large-scale fermentation of sucrose and/or
        glucose. The reaction involving sucrose is summarized below:
      </p>

      <p class="pl-10">
        <chemical-latex content="C12H22O11 + 3O2 -> 2 C6H8O7 + 3 H2O" />
      </p>

      <p class="mb-2">
        Determine the mass, in <b>grams</b>, of citric acid that would be produced from
        <number-value :value="massSucrose" unit="\text{kg}" />
        of sucrose assuming a percent yield of
        <number-value :value="pctYield" unit="\text{\% ?}" />
      </p>

      <calculation-input
        v-model="inputs.massProduct"
        class="mb-0"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />

      <p class="mb-0">
        <small
          ><b>Helpful tip:</b> To enter a number in scientific notation, enter 1.0E5 or 1.0e5, for
          1.0 x 10<sup>5</sup> where the E# (or e#) denotes 10<sup>#</sup></small
        >
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question237',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        massProduct: null,
      },
    };
  },
  computed: {
    massSucrose() {
      return this.taskState.getValueBySymbol('massSucrose').content;
    },
    pctYield() {
      return this.taskState.getValueBySymbol('pctYield').content;
    },
  },
};
</script>
